import { Col, Row, Tab, Tabs } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import URL from "../../constants/routesURL";

function EditOrganisationTabs({ handleTabChanges = () => {}, errors = {}, isProfileEdit=false }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const handleTabChange = (eventKey) => {
    if (Object.keys(errors).length === 0) {
      handleTabChanges();
      navigate(eventKey);
    }
  };
  const header = isProfileEdit ? "Edit Profile" : "Edit Organization"
  const profileDetailEventKey = isProfileEdit ? URL.PROFILE.EDIT : URL.ORGANISATION.EDIT.PROFILE_DETAIL;
  const professionalDetailEventKey = isProfileEdit ? URL.PROFILE.EDIT_PROFESSIONAL : URL.ORGANISATION.EDIT.PROFESSIONAL_DETAIL;
  const paymentDetailEventKey = isProfileEdit ? URL.PROFILE.EDIT_PAYMENT : URL.ORGANISATION.EDIT.PAYMENT;
  return (
    <Row>
      <Col>
        <h1>{header}</h1>
      </Col>
      <Col md={12}>
        <Tabs
          activeKey={pathname}
          id="uncontrolled-tab-example"
          className="organise_tabs"
          onSelect={handleTabChange}
        >
          <Tab
            eventKey={profileDetailEventKey}
            title="Profile Details"
          ></Tab>
          <Tab
            eventKey={professionalDetailEventKey}
            title="Professional Details"
          ></Tab>
          <Tab
            eventKey={paymentDetailEventKey}
            title="Payment Plan"
          ></Tab>
        </Tabs>
      </Col>
    </Row>
  );
}

export default EditOrganisationTabs;
