const apiURL = {
  LOGIN: "/authentication/login/",
  ORGANISATION_SERVICE: "/organization/services/",
  LISTING_OF_LANGUAGES: "/organization/languages/",
  ORGANISATION_CLINIC: "/organization/organization-clinic/",
  ADD_ORGANISATION_CLINIC_DOCUMENT:
    "/organization/organization-document/?organization_id=",
  ORGANISATION_SUMMARY_LIST: "/organization/organization-summary/",
  CLINIC_SUMMARY: "/clinic/clinics-summary/",
  DOCTOR_SUMMARY: "/doctor/doctors-summary/",
  PATIENT_SUMMARY: "/patient/patients-summary/",
  ORGANISATION_LIST: "/organization/organization-only-listing/",
  CLINIC_LIST: "/clinic/clinic-only-listing/",
  DOCTOR_LIST: "/doctor/doctors-only-listing/",
  ORGANISATION_EMAIL_CHECK: "/organization/organization-email-check/",
  ORGANISATION_DOCUMENT: "/organization/organization-document/",
  CLINIC: "/clinic/clinics/",
  CLINIC_DOCUMENT: "/clinic/clinic-document/",
  DOCTOR: "/doctor/doctors/",
  DOCTOR_DOCUMENT: "/doctor/doctors-document/",
  PATIENT: "/patient/patients/",
  ACCEPT_TERMS_AND_CONDITION: "/authentication/check-tc/",
  VERIFY_OTP: "/authentication/verify-otp/",
  RESEND_OTP: "/authentication/resend-otp/",
  CLINIC_EMAIL_CHECK: "/clinic/clinic-email-check/",
  DOCTOR_EMAIL_CHECK: "/doctor/doctor-email-check/",
  PATIENT_EMAIL_CHECK: "/patient/patient-email-check/",
  DME_LOOKUP: "/authentication/dme-lookup/",
  DIAGNOSIS_SEARCH: "/authentication/diagnosis-search/",
  COMPANY_SEARCH: "/authentication/company-search/",
  PATIENT_LIST: "/patient/patient-list/",
  ASSESSMENT_TYPE_LIST: "/patient/assessment-type-list/",
  ASSESSMENT_SUMMARY: "/patient/assessment/",
  ASSESSMENT_CSV: "/patient/assessment-csv/",
  PATIENT_PDF: "/patient/patient-export/",
  FORGOT_PASSWORD: "/authentication/forgot-password/",
  FORGOT_PASSWORD_OTP: "/authentication/forgot-password/verify-otp/",
  FORGOT_PASSWORD_CHANGE: "/authentication/forgot-password/change-password/",
  ASSESSMENT_COLUMN_FILTER: "/patient/assessment-columns/",
  PAIN_ASSESSMENT: "/patient/pain-assessment/",
  IMAGE_UPLOAD: "/patient/image-upload/",
  SCANS_DETAIL: "/patient/scans-detail/",
  PAIN_ASSESSMENT_OLD: "/patient/pain-assessment-old/",
  IMAGE_UPLOAD_OLD: "/patient/image-upload-old/",
  PATIENT_DOCTOR_COUNT: '/patient/patient-doctor-count/'
};

export default apiURL;
