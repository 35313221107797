import PaymentComp from "../../../../components/payment";
import TabsWithNavigation from "../../../../components/tabsWithNavigation";
import { editClinicTabs } from "../../../../constants/clinic.constants";

function EditClinicPayment({isProfileEdit=false}) {
  return (
    <>
      <div className="Patients_section Organization-section AddOrganisationProfile">
        <TabsWithNavigation tabs={editClinicTabs} heading={isProfileEdit ? "Edit Profile" : "Edit Clinic"}  isProfileEdit={isProfileEdit}/>

        <PaymentComp />
      </div>
    </>
  );
}
export default EditClinicPayment;
