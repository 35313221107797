import React from "react";
import { Form } from "react-bootstrap";
import { useFormik } from "formik";
import Input from "../../../components/formGroupInput";
import ButtonWithLoader from "../../../components/buttonWithLoading";
import { validationSchema } from "../../../validation/forgotPasswordChangePassword";
import "./style.scss";

const ChangePassword = ({ onSubmit }) => {
  const { errors, touched, handleSubmit, getFieldProps, values, isSubmitting } = useFormik({
    initialValues: { password: "", confirmPassword: "" },
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  const formikProps = {
    touched: touched,
    errors: errors,
    getFieldProps: getFieldProps,
    values: values,
  };

  return (
    <>
    <Form onSubmit={handleSubmit}>
      <Input
        name="password"
        type="password"
        placeholder="Enter new password"
        label="New Password"
        {...formikProps}
        // showPasswordCriteria
      />
      <Input
        name="confirmPassword"
        type="password"
        placeholder="Confirm new password"
        label="Confirm Password"
        {...formikProps}
      />
      <div className="text-center">
        <ButtonWithLoader
          className="d-flex justify-content-center align-items-center"
          variant="primary"
          isSubmitting={isSubmitting}
          label="Change Password"
        />
      </div>
    </Form>
    <div className="password-criteria">
        <div>Password must contain the following:</div>
        <ol>
          <li>At least one lowercase letter</li>
          <li>At least one uppercase letter</li>
          <li>At least one digit</li>
          <li>At least one special character: !@#$%^&*()_+=</li>
          <li>Minimum length is 8</li>
        </ol>
      </div>
    </>
  );
};

export default ChangePassword;
