import { useContext, useEffect, useState } from "react";
import Payment from "../../organisation/edit/payment";
import { roles } from "../../../constants/common.constants"
import { Store } from "../../../store/Store"
import EditClinicPayment from "../../clinic/edit/payment"


function EditPaymentDetails() {
    const { state, dispatch } = useContext(Store);
    const { userInfo } = state;

    return (
        <>
            {userInfo.user_type === roles.organization && (
                <Payment isProfileEdit={true} />
            )}
            {userInfo.user_type === roles.clinic && <EditClinicPayment isProfileEdit={true} />}
        </>
    );
}
export default EditPaymentDetails;