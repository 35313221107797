import React  from "react";
import {  Form } from "react-bootstrap";
import { useFormik } from "formik";
import Input from "../../../components/formGroupInput";
import ButtonWithLoader from "../../../components/buttonWithLoading";
import { validationSchema } from "../../../validation/forgotPasswordEmail";
import "./style.scss";

const ForgotPasswordEmail = ({ onSubmit }) => {

    const { errors, touched, handleSubmit, getFieldProps, isSubmitting } = useFormik({
        initialValues: { email: "" },
        validationSchema: validationSchema,
        onSubmit: onSubmit,
    });

    const formikProps = {
        touched: touched,
        errors: errors,
        getFieldProps: getFieldProps,
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Input
                name="email"
                type="email"
                placeholder="Enter your email"
                label="Please enter your email address to reset password"
                {...formikProps}
            />
            <div className="text-center">
                <ButtonWithLoader
                    className="d-flex justify-content-center align-items-center"
                    variant="primary"
                    isSubmitting={isSubmitting}
                    label="Send OTP"
                />
            </div>
        </Form>
    );
};

export default ForgotPasswordEmail;
