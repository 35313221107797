import React, { useState, useRef, useEffect } from "react";
import { Form } from "react-bootstrap";
import ButtonWithLoader from "../../../components/buttonWithLoading";
import { validationSchema } from "../../../validation/forgotPasswordOtp";
import "./style.scss";

const VerifyOtp = ({ onSubmit, loading, resendOtp, email, seconds }) => {
    const [otpValues, setOtpValues] = useState(["", "", "", ""]);
    const [errors, setErrors] = useState({});
    const otpInputRefs = useRef(otpValues.map(() => React.createRef()));

    const validateOtp = (otp) => {
        try {
            validationSchema.validateSync({ otp });
            setErrors({});
            return true;
        } catch (error) {
            setErrors({ otp: error.message });
            return false;
        }
    };

    const handleOtpChange = (index, value) => {
        if (/^\d?$/.test(value)) {
            const newOtpValues = [...otpValues];
            newOtpValues[index] = value;
            setOtpValues(newOtpValues);

            // Move focus to the next input when a digit is entered
            if (value && index < otpInputRefs.current.length - 1) {
                otpInputRefs.current[index + 1].current.focus();
            }

            // Move focus to the previous input on backspace if the current input is empty
            if (!value && index > 0) {
                otpInputRefs.current[index - 1].current.focus();
            }
        }
    };

    // const resendOtp = () => {
    //     resendOtp
    // }

    const handlePaste = (e) => {
        const pasteData = e.clipboardData.getData("text").slice(0, 4);
        if (/^\d{1,4}$/.test(pasteData)) {
            const newOtpValues = pasteData.split("").map((char, index) => {
                if (index < otpValues.length) {
                    otpInputRefs.current[index].current.value = char;
                    return char;
                }
                return otpValues[index];
            });
            setOtpValues(newOtpValues);

            // Move focus to the last non-empty input field
            const lastIndex = pasteData.length - 1;
            if (lastIndex < otpInputRefs.current.length) {
                otpInputRefs.current[lastIndex].current.focus();
            }
        } else {
            setErrors({ otp: "Invalid OTP format. Please enter only digits." });
        }
        e.preventDefault();
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const otp = otpValues.join("");
        if (validateOtp(otp)) {
            onSubmit({ otp });
        }
    };

    const forgotPassResendOtp = () => {
        setOtpValues(["", "", "", ""])
        resendOtp({ "email": email })
    }


    return (
        <>
            <p className="text-center">
                We've sent a One-Time Password (OTP) to your email address.
                <br />
                Please enter the OTP below to verify and proceed
            </p>
            <Form onSubmit={handleSubmit}>
                <div className="d-flex verification_code">
                    {otpValues.map((value, index) => (
                        <div key={index}>
                            <input
                                type="text"
                                value={value}
                                maxLength={1}
                                onChange={(e) => handleOtpChange(index, e.target.value)}
                                ref={otpInputRefs.current[index]}
                                onPaste={handlePaste}
                            />
                        </div>
                    ))}
                </div>
                {errors.otp && (
                    <div className="text-center text-danger">{errors.otp}</div>
                )}
                <div className="text-center">
                    <ButtonWithLoader
                        className="d-flex justify-content-center align-items-center"
                        variant="primary"
                        isSubmitting={loading}
                        label="Verify OTP"
                    />
                </div>
                <div className="text-center resendOtp" onClick={forgotPassResendOtp}>
                    {seconds !== 0
                        ? `Resend Code in ${seconds} seconds`
                        : "Resend Code"}
                </div>
            </Form>
        </>
    );
};

export default VerifyOtp;
